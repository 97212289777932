<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.383"
    height="22"
    viewBox="0 0 19.383 22"
    class="stroke-primary"
  >
    <path
      id="iconmonstr-trash-can-thin"
      d="M8.048,2.75h5.286v-1.6A.234.234,0,0,0,13.27.984a.216.216,0,0,0-.156-.067H8.269a.216.216,0,0,0-.156.067.234.234,0,0,0-.064.162Zm9.691.917H3.643v16.5a.9.9,0,0,0,.881.917H16.859a.9.9,0,0,0,.881-.917ZM8.929,6.875a.441.441,0,1,0-.881,0v11a.441.441,0,1,0,.881,0Zm4.405,0a.441.441,0,1,0-.881,0v11a.441.441,0,1,0,.881,0ZM20.383,2.75v.917H18.621v16.5A1.8,1.8,0,0,1,16.859,22H4.524a1.8,1.8,0,0,1-1.762-1.833V3.667H1V2.75H7.167V.917A.9.9,0,0,1,8.048,0h5.286a.9.9,0,0,1,.881.917V2.75Z"
      transform="translate(-1)"
      fill="#747575"
      fill-rule="evenodd"
    />
  </svg>
</template>
