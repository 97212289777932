<template>
  <BotResponse
    v-if="props.user.first_name == 'Auxo AI'"
    :text="props.message"
    :user="props.user"
    :timestamp="props.timestamp"
    :message-i-d="props.messageID"
  ></BotResponse>
  <div v-else class="flex items-start gap-2.5">
    <div class="flex flex-col gap-1 w-full">
      <div class="flex items-center space-x-2 ltr:space-x-reverse justify-end">
        <span class="text-sm font-semibold text-gray-900 dark:text-white"
          >{{ props.user.first_name }} {{ props.user.last_name }}</span
        >
        <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
          {{
            props.timestamp.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          }}
        </span>
      </div>
      <div
        class="flex flex-col leading-1.5 p-4 border-gray-200 bg-[#cceffe] rounded-s-2xl rounded-ee-2xl dark:bg-gray-700"
      >
        <p
          class="font-normal text-gray-900 dark:text-white overflow-auto whitespace-pre-wrap break-words"
        >
          {{ props.message }}
        </p>
      </div>
    </div>
    <UserPicture :user="props.user" :disable-popper="true" />
  </div>
</template>

<script setup>
import UserPicture from '../UserPicture.vue';
import BotResponse from './BotResponse.vue';

const props = defineProps({
  user: {
    type: {},
    default: {
      first_name: 'Auxo AI',
      last_name: '',
      initials: 'AI',
      photo_url: 'AI Photo',
    },
  },
  message: {
    type: String,
    default: '',
  },
  timestamp: {
    type: Date,
    default: new Date(),
  },
  messageID: {
    type: String,
    default: null,
  },
});
</script>
