<template>
  <div class="flex flex-col w-full text-xs">
    <div
      v-show="workItem_store.isLoading || users_store.usersLoading"
      class="flex bg-white h-36 p-2 justify-center items-center text-sm text-primary"
    >
      <LoadingSpinner />
    </div>
    <div
      v-show="
        workItem_store.workItemActivities.length === 0 &&
        !workItem_store.isLoading
      "
      class="flex bg-white h-36 p-2 justify-center items-center text-rev-base text-primary"
    >
      No activities found
    </div>
    <div
      v-for="(activity, i) in workItem_store.activityPaginated"
      v-show="!workItem_store.isLoading && !users_store.usersLoading"
      :key="i"
    >
      <!-- Normal activity stream record -->
      <div class="p-2" :class="getRowColor(i)">
        <div class="text-gray-500 text-rev-sm font-semibold">
          {{ formatDate(activity.mod_timest, 'Pp', false) }}
        </div>
        <template v-if="activity.activity_type_id !== 6">
          <!-- Normal activity render, show note if not being edited -->
          <div v-if="activity.activity_id !== editingActivityId">
            <div class="flex mt-1">
              <div class="text-primary text-rev-sm mr-2 font-semibold">
                {{ activity.activity_type }}
                <span v-show="activity.activity_subtype !== null" class="fixed"
                  >:</span
                >
              </div>
              <div class="text-rev-sm font-semibold">
                {{ activity.activity_subtype }}
              </div>
            </div>
            <div class="my-1 text-rev-sm">{{ activity?.note }}</div>
          </div>
          <!-- in edit mode, render date and created by around this component -->
          <div v-else>
            <ActivityActionAndNotes
              :edit-flag="true"
              :activity-draft="editDraft"
              :activity-id="editingActivityId"
              unique-drop-down-str="edit"
              @exit-edit-mode="() => closeEdit()"
            />
          </div>
          <div class="flex items-center font-light text-gray-500 text-rev-sm">
            Created by:
            <span class="text-primary text-rev-sm font-semibold">{{
              users_store.getUserByUsername(activity.username)?.full_name
            }}</span>
            <!-- edit/delete icon buttons -->
            <button
              v-show="activity.showDeleteIcon && !editModeEnabled"
              class="ml-1"
              @click="modalOpen(activity)"
            >
              <img :src="TrashIcon" />
            </button>
            <button
              v-show="activity.showEditIcon && !editModeEnabled"
              class="ml-1"
              @click="handleEdit(activity)"
            >
              <EditIconComments />
            </button>
          </div>
        </template>
        <template v-if="activity.activity_type_id == 6">
          <div class="flex mt-1">
            <div class="text-primary text-rev-sm mr-2">
              Claim Status Check
              <span v-show="activity.activity_subtype !== null" class="fixed"
                >:</span
              >
            </div>
          </div>
          <div v-if="safeParse(activity.note, 'claim_number')">
            <b>Claim Number:</b> {{ safeParse(activity.note, 'claim_number') }}
          </div>
          <div v-if="safeParse(activity.note, 'icn')">
            <b>ICN:</b> {{ safeParse(activity.note, 'icn') }}
          </div>
          <div v-if="safeParse(activity.note, 'status')">
            <b>Status:</b> {{ safeParse(activity.note, 'status') }}
          </div>
          <div v-if="safeParse(activity.note, 'received_date')">
            <b>Received Date:</b>
            {{ safeParse(activity.note, 'received_date') }}
          </div>
          <div v-if="safeParse(activity.note, 'processed_date')">
            <b>Processed Date:</b>
            {{ safeParse(activity.note, 'processed_date') }}
          </div>
          <div
            v-if="
              safeParse(activity.note, 'status_category_code') &&
              safeParse(activity.note, 'status_category_code_description')
            "
          >
            <b>Status Category:</b>
            {{ safeParse(activity.note, 'status_category_code') }} -
            {{ safeParse(activity.note, 'status_category_code_description') }}
          </div>
          <div
            v-if="
              safeParse(activity.note, 'status_code') &&
              safeParse(activity.note, 'status_code_description')
            "
          >
            <b>Status Code:</b>
            {{ safeParse(activity.note, 'status_code') }} -
            {{ safeParse(activity.note, 'status_code_description') }}
          </div>
          <div v-if="safeParse(activity.note, 'check_number')">
            <b>Check Number:</b> {{ safeParse(activity.note, 'check_number') }}
          </div>
          <div v-if="safeParse(activity.note, 'remittance_date')">
            <b>Remittance Date:</b>
            ${{ safeParse(activity.note, 'remittance_date') }}
          </div>
          <div v-if="safeParse(activity.note, 'check_date')">
            <b>Check Date:</b>
            {{ formatDate(safeParse(activity.note, 'check_date'), 'P', true) }}
          </div>
          <div v-if="safeParse(activity.note, 'payment_amount')">
            <b>Payment Amount:</b>
            ${{ safeParse(activity.note, 'payment_amount') }}
          </div>
          <div class="font-light text-gray-500">
            Created by:
            <span class="text-primary">{{
              users_store.getUserByUsername(activity.username)?.full_name
            }}</span>
          </div>
        </template>
      </div>
    </div>
    <VuePagination
      v-if="!workItem_store.isLoading && !users_store.usersLoading"
      :page="workItem_store.activityPage"
      local_store="activity_pagination"
      type="Recent Activities"
      :records="workItem_store.activityRecords"
      :limit="Number(workItem_store.activityLimit)"
      :show_page_buttons="false"
      @paginate="paginate"
      @updateLimit="updateLimit"
    />
  </div>
</template>
<script setup>
import { defineProps, inject, onUnmounted, onMounted } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { useUsersStore } from '@/stores/useUsers';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { formatDate } from '@/utils/helpers';
import VuePagination from '@/views/VuePagination.vue';
import TrashIcon from '@/assets/trash-icon-for-comments.svg';
import EditIconComments from '@/assets/EditIconComments.vue';
import ActivityActionAndNotes from '../ActivityActionAndNotes.vue';
import { ref } from 'vue';

const workItem_store = useWorkItemsStore();
const users_store = useUsersStore();
const editModeEnabled = ref(false);
const editingActivityId = ref(null);
const editDraft = ref(null);

const deleteModalControls = inject('deleteActivityModalControls');

const getRowColor = i => {
  return i % 2 === 0 ? 'bg-white' : 'bg-gray-100';
};

function safeParse(activity, key) {
  try {
    return JSON.parse(activity)[key];
  } catch {
    console.error(`Error in parsing claim status field ${key}`);
  }
}

async function paginate(page) {
  await workItem_store.fetchWorkItemActivities(
    props.workItemId,
    page,
    workItem_store.activityDirection,
    workItem_store.activityField
  );
}

async function updateLimit(limit) {
  workItem_store.activityPage = 1;
  workItem_store.activityLimit = Number(limit.value);
}

/** create editDraft to pass into ActivityActionAndNotes */
const handleEdit = activity => {
  workItem_store.editActivityModeEnabled = true;
  editingActivityId.value = activity.activity_id;
  let noteWithoutEditString = activity.note.replace(' (Edited)', '');
  editDraft.value = {
    auxo_activity_type_id: activity.activity_type_id,
    auxo_activity_subtype_id: activity.activity_subtype_id,
    note: noteWithoutEditString,
  };
  editModeEnabled.value = !editModeEnabled.value;
};

/** user selected cancel, close out of edit mode */
const closeEdit = () => {
  workItem_store.editActivityModeEnabled = false;
  editingActivityId.value = null;
  editDraft.value = null;
  editModeEnabled.value = false;
  return;
};

function modalOpen(activity) {
  workItem_store.activityToDelete = activity;
  deleteModalControls.value.show();
}

/** for if user leaves pages with modal open, or in edit mode */
onUnmounted(() => {
  workItem_store.editActivityModeEnabled = false;
  workItem_store.activityToDelete = {};
  deleteModalControls.value.hide();
});

onMounted(() => {
  const current_limit = useLocalStorage(
    'activity_pagination',
    Number(workItem_store.activityLimit)
  );
  workItem_store.activityLimit = current_limit;
});

const props = defineProps({
  workItemId: {
    type: String,
    default: '',
  },
});
</script>
